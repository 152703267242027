export const reportReasons = {
    '1' : 'Fake photos',
    '2' : 'Rude behavior',
    '3' : 'Scam',
    '4' : 'Escort',
    '5' : 'Profile duplicate',
    '6' : 'For contacts',
    '7' : 'Payment scam',
    '8' : 'Celebrity photos',
    '9' : 'Other',
    '10': 'Block Permanently'
}
