import {useSelector} from "react-redux";

const Sidebar = () =>  {
    const menuList = useSelector(s => s.auth.view.sidebarMenu);
    return (
        <div className="main-sidebar-left">
            <div id="usermenu">
                {Object.entries(menuList).map(([blockName, block]) => {
                    return (!block.tabEnabled) ? '' : (
                        <ul key={blockName}>
                            <b>{blockName}</b>
                            <Links links={block} />
                        </ul>
                    )
                })}
            </div>
        </div>
    )
};

const Links = ({links}) => Object.entries(links).map(([key, link]) => (
    <div key={key}>
        {(link.isEnabled) ? <li><a href={link.url}>{link.name}</a></li> : ''}
    </div>
));

export default Sidebar;
